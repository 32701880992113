/* eslint-disable no-param-reassign */

import { parseScriptFromResponse, redirectWithSleep } from '../index';
import { prefixCountryFormSubmit } from '../api';

export const onSubmit = async (
  values,
  togglePopup,
  toggleErrorPopup,
  features,
  setError,
  form__emailError3,
  form__emailError2,
  form__phoneError2,
  form__phoneError6,
  form__countryBlockedError,
  form__nameError,
  form__passwordError,
  toggleLoader,
  csrf,
) => {
  const { country_prefix } = values;
  values.country_prefix = country_prefix.replace('+', '');
  // NOTE - TEMPORARY fix for validation for Dominicana:
  if (values.iso === 'DO') {
    values.country_prefix = '1';
  }
  // END
  values.csrf = csrf;

  toggleLoader(true);

  const { data, status, error } = await prefixCountryFormSubmit(values);
  toggleLoader(false);

  if (status >= 200 && status < 300) {
    await parseScriptFromResponse({ data: data.pixel, isRegistration: true });
    data?.meta?.redirectTo
      ? await redirectWithSleep(data.meta.redirectTo, toggleLoader)
      : togglePopup(true);
  } else {
    // TODO: check why toggleLoader needs to be duplicated
    toggleLoader(false);
    const { type = '' } = error && error?.errors;
    if (type === 'ERROR_EMAIL_DUPLICATE') {
      return setError('user_email', {
        type: 'manual',
        message: form__emailError3,
      });
    }
    if (type === 'ERROR_EMAIL_INVALID') {
      return setError('user_email', {
        type: 'manual',
        message: form__emailError2,
      });
    }
    if (type === 'ERROR_MOBILE_INVALID') {
      return setError('phone_num', {
        type: 'manual',
        message: form__phoneError2,
      });
    }
    if (type === 'ERROR_PHONE_DUPLICATE') {
      return setError('phone_num', {
        type: 'manual',
        message: form__phoneError6,
      });
    }
    if (type === 'ERROR_COUNTRY_BLOCKED') {
      return setError('phone_num', {
        type: 'manual',
        message: form__countryBlockedError,
      });
    }
    if (type === 'ERROR_FIRST_NAME_INVALID') {
      setError('full_name', {
        type: 'manual',
        message: form__nameError,
      });

      return setError('first_name', {
        type: 'manual',
        message: form__nameError,
      });
    }
    if (type === 'ERROR_LAST_NAME_INVALID') {
      setError('full_name', {
        type: 'manual',
        message: form__nameError,
      });

      return setError('last_name', {
        type: 'manual',
        message: form__nameError,
      });
    }
    if (type === 'ERROR_PASSWORD_VALIDATION') {
      return setError('password', {
        type: 'manual',
        message: form__passwordError,
      });
    }
    if (type === 'ERROR_FULL_NAME_INVALID') {
      return setError('full_name', {
        type: 'manual',
        message: form__nameError,
      });
    }
    toggleErrorPopup(true);
  }
};

export default onSubmit;
